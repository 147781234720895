import { render, staticRenderFns } from "./6.5.vue?vue&type=template&id=12688721&scoped=true"
import script from "./6.5.vue?vue&type=script&lang=js"
export * from "./6.5.vue?vue&type=script&lang=js"
import style0 from "./6.5.vue?vue&type=style&index=0&id=12688721&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12688721",
  null
  
)

export default component.exports